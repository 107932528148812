<template>
  <div class="mainParentContainer ignoreFontUpscale tw-pt-6 md:tw-pt-12">
    <InfluencerOnboardingFlowThiagoBubble>
      {{ $t(`influencer.signup.bubble.services`) }}
    </InfluencerOnboardingFlowThiagoBubble>
    <div class="tw-max-w-3xl lg:tw-ml-48">
      <div class="tw-mb-8 tw-flex tw-flex-col tw-gap-2">
        <VTextArea
          v-model="servicesFieldValue"
          data-test-id="curatorSignupServicesTextarea"
          :name="servicesFieldName"
          :placeholder="
            $t('influencer.signup.services.description.placeholder')
          "
          :label="$t('influencer.signup.services.description.label')"
          :errmsg="$t('influencer.signup.services.description.error')"
          :p-validity="!servicesFieldErrorMessage"
        >
          <template #info>
            <VText
              v-if="!servicesFieldErrorMessage"
              cfg="sans/14/regular"
              color="gray-500"
            >
              {{ $t('influencer.signup.services.description.helper') }}
            </VText>
          </template>
        </VTextArea>
      </div>
    </div>
    <InfluencerOnboardingFlowNavigationFooter @next="handleNext" />
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/nuxt'
import { mapActions, mapStores } from 'pinia'
import { useForm } from 'vee-validate'
import { z } from 'zod'

import InfluencerOnboardingFlowNavigationFooter from '~/components/influencer/signup/InfluencerOnboardingFlowNavigationFooter.vue'
import InfluencerOnboardingFlowThiagoBubble from '~/components/influencer/signup/InfluencerOnboardingFlowThiagoBubble.vue'
import VTextArea from '~/components/ui/Inputs/VTextarea.vue'
import VText from '~/components/ui/VText.vue'

import { useCuratorOnboardingFlow } from '~/composables/useCuratorOnboardingFlow'
import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { useInfluencerOnboardingFlowStore } from '~/stores/influencerOnboardingFlow'
import { useTagStore } from '~/stores/tag'
import { useUserStore } from '~/stores/user'
import { useUserInfluencerStore } from '~/stores/userInfluencer'
import { useUserInfluencerTagsStore } from '~/stores/userInfluencerTags'

import { scrollFieldWithErrorIntoView } from '~/helpers/scrollFieldWithErrorIntoView'

import { provideCuratorRefreshCache } from '~/api-core/Curators/CuratorRefreshCache'
import { providePatchInfluencer } from '~/api-core/Curators/PatchInfluencer'

import type { Influencer } from '~/types/influencer'

export default defineNuxtComponent({
  layout: 'onboarding-flow',
  components: {
    InfluencerOnboardingFlowNavigationFooter,
    InfluencerOnboardingFlowThiagoBubble,
    VText,
    VTextArea,
  },
  setup() {
    definePageMeta({
      layout: 'onboarding-flow',
      middleware: ['onboarding-flow'],
    })

    const router = useRouter()
    const { t } = useI18n()
    const { coreFetch } = useProvideCoreFetch()
    const userInfluencerStore = useUserInfluencerStore()
    const influencerOnboardingFlowStore = useInfluencerOnboardingFlowStore()
    const { wordCountCheck } = useCuratorOnboardingFlow()
    const { GET_TAG_TYPE_FROM_NAME, GET_TAGS_FROM_IDS } = useTagStore()

    influencerOnboardingFlowStore.CHECK_ACTIVE_STEP(
      router.currentRoute.value.path,
    )

    const { values: formValues, validate: validateForm } = useForm<{
      services: string
    }>({
      initialValues: {
        services: userInfluencerStore.service_details_en,
      },
    })

    const servicesFieldName = 'services'

    const {
      value: servicesFieldValue,
      errorMessage: servicesFieldErrorMessage,
    } = useFieldValidation<string>(
      servicesFieldName,
      z
        .string()
        .refine(
          (s) => wordCountCheck(s),
          t('influencer.signup.services.description.error'),
        ),
      { syncVModel: true, validateOnValueUpdate: false },
    )

    return {
      router,
      formValues,
      validateForm,
      servicesFieldName,
      servicesFieldValue,
      servicesFieldErrorMessage,
      coreFetch,
      GET_TAG_TYPE_FROM_NAME,
      GET_TAGS_FROM_IDS,
    }
  },
  computed: {
    ...mapStores(useUserStore),
    ...mapStores(useUserInfluencerStore),
    ...mapStores(useUserInfluencerTagsStore),
    USER() {
      return this.userStore.$state
    },
    INFLUENCER() {
      return this.userInfluencerStore.$state
    },
  },
  methods: {
    ...mapActions(useInfluencerOnboardingFlowStore, {
      GO_TO_NEXT_STEP: 'GO_TO_NEXT_STEP',
    }),
    ...mapActions(useUserInfluencerStore, { SET_INFLUENCER: 'SET' }),
    ...mapActions(useUserInfluencerTagsStore, {
      UPDATE_INFLUENCER_TAGS_FROM_PATCH: 'UPDATE_FROM_PATCH',
    }),
    async patchInfluencerServices(): Promise<Influencer> {
      return await providePatchInfluencer(this.coreFetch, this.INFLUENCER.id, {
        service_details_en: this.servicesFieldValue,
      })()
    },
    async refreshCache() {
      await provideCuratorRefreshCache(this.coreFetch)(this.INFLUENCER.id)
    },
    async handleNext() {
      const { valid, errors } = await this.validateForm()
      if (!valid) {
        scrollFieldWithErrorIntoView(errors)
        return
      }

      try {
        const influencer = await this.patchInfluencerServices()

        this.SET_INFLUENCER(influencer)

        await this.refreshCache()

        this.GO_TO_NEXT_STEP()
      } catch (error) {
        Sentry.captureException(error)
      }
    },
  },
})
</script>
