import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'
import { nextTick } from 'vue'

import { useUserInfluencerTagsStore } from './userInfluencerTags'

import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import type { UserInfluencerTagsState } from './userInfluencerTags'
import type MediaLinks from '~/types/mediaLinks'
import type { OpportunityName } from '~/types/Opportunity'
import type SpotifyArtist from '~/types/spotifyArtist'

const initialState = () => ({
  authorized: false,
  authorization_status: 'pending',
  authorization_reason: '',
  visible: false,
  internal_status: '',
  has_profile_picture: false,
  has_banner: false,
  has_sendtrack_picture: false,
  is_scout: false,
  description: '',
  description_fr: '',
  description_en: '',
  entity: '',
  link: '',
  slug: '',
  service_details: '',
  service_details_en: '',
  service_details_fr: '',
  id: 0,
  media_links: {} as MediaLinks,
  decisions: [] as number[],
  spotify_playlists: [] as { spotify_id: string }[],
  similar_artists: [] as SpotifyArtist[],
  primary_opportunities: [] as OpportunityName[],
  secondary_opportunities: [] as OpportunityName[],
})

const state = initialState

export type IUserInfluencerState = ReturnType<typeof state>

export interface UserInfluencerState extends IUserInfluencerState {
  tags: UserInfluencerTagsState
}

export const useUserInfluencerStore = defineStore('userInfluencer', {
  state: (): IUserInfluencerState => ({ ...initialState() }),
  actions: {
    SET(data: Partial<Record<keyof UserInfluencerState, any>>) {
      const ignoreKey = ['tags']
      let key: keyof UserInfluencerState

      for (key in data) {
        if (
          // @ts-expect-error trust
          typeof this[key] === typeof data[key] &&
          !ignoreKey.includes(key)
        )
          // @ts-expect-error trust
          this[key] = data[key]
      }
    },
    FORCE_PICTURE_REFRESH() {
      this.SET({ has_profile_picture: !this.has_profile_picture })
      nextTick(() => {
        this.SET({ has_profile_picture: !this.has_profile_picture })
      })
    },
    RESET() {
      const userInfluencerTagsStore =
        useUserInfluencerTagsStore(getActivePinia())

      userInfluencerTagsStore.RESET()
      resetStoreToInitialState.bind(this)(initialState())
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useUserInfluencerStore, import.meta.hot),
  )
}
